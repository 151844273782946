$bs-blue: #375a7f;
$bs-indigo: #6610f2;
$bs-purple: #6f42c1;
$bs-pink: #e83e8c;
$bs-red: #e74c3c;
$bs-orange: #fd7e14;
$bs-yellow: #f39c12;
$bs-green: #00bc8c;
$bs-teal: #20c997;
$bs-cyan: #3498db;
$bs-black: #000;
$bs-white: #fff;
$bs-gray: #888;
$bs-gray-dark: #303030;
$bs-gray-100: #f8f9fa;
$bs-gray-200: #ebebeb;
$bs-gray-300: #dee2e6;
$bs-gray-400: #ced4da;
$bs-gray-500: #adb5bd;
$bs-gray-600: #888;
$bs-gray-700: #444;
$bs-gray-800: #303030;
$bs-gray-900: #222;
$bs-primary: #375a7f;
$bs-secondary: #444;
$bs-success: #00bc8c;
$bs-info: #3498db;
$bs-warning: #f39c12;
$bs-danger: #e74c3c;
$bs-light: #adb5bd;
$bs-dark: #303030;
$bs-primary-rgb: 55, 90, 127;
$bs-secondary-rgb: 68, 68, 68;
$bs-success-rgb: 0, 188, 140;
$bs-info-rgb: 52, 152, 219;
$bs-warning-rgb: 243, 156, 18;
$bs-danger-rgb: 231, 76, 60;
$bs-light-rgb: 173, 181, 189;
$bs-dark-rgb: 48, 48, 48;
$bs-white-rgb: 255, 255, 255;
$bs-black-rgb: 0, 0, 0;
$bs-body-color-rgb: 255, 255, 255;
$bs-body-bg-rgb: 34, 34, 34;
$bs-font-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace;
$bs-gradient: linear-gradient(
  180deg,
  hsla(0, 0%, 100%, 0.15),
  hsla(0, 0%, 100%, 0)
);
$bs-body-font-family: var($bs-font-sans-serif);
$bs-body-font-size: 1rem;
$bs-body-font-weight: 400;
$bs-body-line-height: 1.5;
$bs-body-color: #fff;
$bs-body-bg: #222;
$bs-border-width: 1px;
$bs-border-style: solid;
$bs-border-color: #dee2e6;
$bs-border-color-translucent: rgba(0, 0, 0, 0.175);
$bs-border-radius: 0.375rem;
$bs-border-radius-sm: 0.25rem;
$bs-border-radius-lg: 0.5rem;
$bs-border-radius-xl: 1rem;
$bs-border-radius-2xl: 2rem;
$bs-border-radius-pill: 50rem;
$bs-link-color: #00bc8c;
$bs-link-hover-color: #009670;
$bs-code-color: #e83e8c;
$bs-highlight-bg: #fdebd0;
