@import "./bootstrap";
.root.solid {
  background-color: $bs-blue;
}

.root {
  transition: all 256ms ease;
  background: linear-gradient($bs-blue, #0000);
  position: fixed;
  z-index: 1024;
  border-bottom: none;
  overflow: hidden;
}

.smooth {
  transition-property: transform, font-size;
  transition-duration: 200ms;
}

.brand {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.25rem;
}

.brandName {
  color: #fff;
  font-size: 24px;
  transform: translateX(-8px);
  text-shadow: 2px 2px 1px #000000;
}

.brandName.top {
  font-size: 32px;
  transform: translate(16px,0);
}

// .mascot {
//   margin-top: -6px;
//   position: relative;
//   border-radius: 50%;
// }
.mascot {
  position: relative;
  z-index: -1;
  transform: translate(0, -8px);
  width: 5rem;
  height: auto;
}

.mascot:not(.top) {
  transform: scale(0.9);
}
