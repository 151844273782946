.banner {
  position: relative;
  z-index: 1;
  background: linear-gradient(130deg, #2d2c37 42%, #201f28b3 63%, transparent),
    url(../assets/images/BannerEffect.gif) center / cover no-repeat;
  padding: 0px;
  color: #fff;
}

.content {
  padding-top: 25px;
  position: relative;
  padding-right: 28px;
  padding-left: 28px;
  padding-bottom: 25px;
  z-index: 2;
}

.welcome {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.lg_brandname {
  font-weight: normal;
  font-size: 37px;
}

.ip {
  font-weight: normal;
  font-size: 24px;
  color: gold;
  cursor: pointer;
}
