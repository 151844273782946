.nav_root___kV3Y.nav_solid__ZcHYy{background-color:#375a7f}.nav_root___kV3Y{transition:all 256ms ease;background:linear-gradient(#375a7f, rgba(0, 0, 0, 0));position:fixed;z-index:1024;border-bottom:none;overflow:hidden}.nav_smooth__K_62T{transition-property:transform,font-size;transition-duration:200ms}.nav_brand__rHna9{display:flex;align-items:center;color:#fff;font-size:1.25rem}.nav_brandName__6BBqA{color:#fff;font-size:24px;transform:translateX(-8px);text-shadow:2px 2px 1px #000}.nav_brandName__6BBqA.nav_top__d0QSt{font-size:32px;transform:translate(16px, 0)}.nav_mascot__k3oOf{position:relative;z-index:-1;transform:translate(0, -8px);width:5rem;height:auto}.nav_mascot__k3oOf:not(.nav_top__d0QSt){transform:scale(0.9)}
@font-face {
font-family: '__Minecraft_fee371';
src: url(/_next/static/media/738bad905cdb7715.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Minecraft_fee371';
src: url(/_next/static/media/2322205c984f9563.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__Minecraft_fee371';
src: url(/_next/static/media/f64115b5ce06e5ca.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__Minecraft_fee371';
src: url(/_next/static/media/862165f5f0fb750d.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__Minecraft_Fallback_fee371';src: local("Arial");ascent-override: 85.73%;descent-override: 17.16%;line-gap-override: 0.00%;size-adjust: 116.64%
}.__className_fee371 {font-family: '__Minecraft_fee371', '__Minecraft_Fallback_fee371'
}

.banner_banner__nNOXV{position:relative;z-index:1;background:linear-gradient(130deg, #2d2c37 42%, rgba(32, 31, 40, 0.7019607843) 63%, transparent),url(/_next/static/media/BannerEffect.4d2f3a9d.gif) center/cover no-repeat;padding:0px;color:#fff}.banner_content__jxVRf{padding-top:25px;position:relative;padding-right:28px;padding-left:28px;padding-bottom:25px;z-index:2}.banner_welcome__L_fCR{margin-bottom:0;font-size:1.25rem;font-weight:500}.banner_lg_brandname__N0Som{font-weight:normal;font-size:37px}.banner_ip__Q6llf{font-weight:normal;font-size:24px;color:gold;cursor:pointer}
.layout_wrapper__ZqPXY{min-height:100vh;background-color:#27262e}.layout_container__8mgcn{padding-top:75px}.layout_pane__R5fnn{border-radius:10px}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/b60fc9d2d030b5e6.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/2c91708671b37a8b.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/c92ff110d0ef9b86.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/dc9ab78c2735f6b0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/b89f66ecdb077e7f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/b60fc9d2d030b5e6.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/2c91708671b37a8b.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/c92ff110d0ef9b86.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/dc9ab78c2735f6b0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_c9c81f';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/b89f66ecdb077e7f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_c9c81f';src: local("Arial");ascent-override: 99.46%;descent-override: 34.73%;line-gap-override: 0.00%;size-adjust: 101.65%
}.__className_c9c81f {font-family: '__Nunito_c9c81f', '__Nunito_Fallback_c9c81f';font-style: normal
}

