@import "./bootstrap";

.wrapper {
  min-height: 100vh;
  // margin: 0;
  background-color: #27262e;
}
.container {
  padding-top: 75px;
}
.pane {
  border-radius: 10px;
}
